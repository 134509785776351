import { NextPageContext } from 'next/dist/shared/lib/utils'
import { getEnvGlobal } from 'config'
import qs, { StringifiableRecord, StringifyOptions } from 'query-string'
import { isEmpty } from 'ramda'
import { asArray } from 'lib/array'
import { isServerSide } from 'lib/env'

// ---------------
// URL ENCODING
// ---------------

const ARRAY_FORMAT_SEPARATOR = ';'

const qsOptions: StringifyOptions = {
    strict: false, // so to match the `encodePart`
    encode: true, // even though we take care of custom encoding ourselves

    arrayFormat: 'separator',
    arrayFormatSeparator: ARRAY_FORMAT_SEPARATOR,
} as const

const preEncode = (urlPart: string) => urlPart.replace(/\+/g, ' ')
const postEncode = (urlPart: string) => urlPart.replace(/%20/g, '+')

// Corresponds to `wh.routes/prepare-path-param` and provides custom encoding
const encodePart = (urlPart: string) => {
    return postEncode(encodeURIComponent(preEncode(urlPart)))
}

const encoders = {
    value: (value: any) => {
        return encodePart(value.toString())
    },
    array: (values: any[]) => {
        return values.map(value => encoders.value(value)).join(ARRAY_FORMAT_SEPARATOR)
    },
}

// NB: This function is only necessary for the `path` part of the URL (or its segment).
export function encodePath(pathSegment: string | string[] | undefined): string {
    if (pathSegment === undefined) {
        return ''
    }
    return Array.isArray(pathSegment)
        ? encoders.array(pathSegment)
        : encoders.value(pathSegment)
}

export const addQueryParams = (url: string, params?: StringifiableRecord) => {
    if (params === undefined || isEmpty(params)) {
        return url
    }

    // NB: In order not to repeat the logic of the library used,
    //     we simply prepare string parameter values solely.
    const query: StringifiableRecord = {}
    Object.entries(params).map(([paramName, paramValue]) => {
        if (typeof paramValue === 'string') {
            query[paramName] = preEncode(paramValue)
        } else if (Array.isArray(paramValue) && typeof paramValue[0] === 'string') {
            query[paramName] = paramValue.map(value => preEncode(value))
        } else {
            query[paramName] = paramValue
        }
    })

    return postEncode(qs.stringifyUrl({ url, query }, qsOptions))
}

// ---------------
// URL DECODING
// ---------------

// Corresponds to `wh.common.search/parse-param` and provides custom encoding
const decodePart = (encodedUrlPart: string) => {
    return decodeURIComponent(encodedUrlPart).replace(/\+/g, ' ')
}

export const decoders = {
    string: (value: string) => {
        return decodePart(value)
    },
    boolean: (value: string) => {
        return value === 'true' ? true : undefined
    },
    posInt: (value: string) => {
        const number = parseInt(value)
        if (Number.isNaN(number)) {
            return undefined
        }
        if (number < 0) {
            return 0
        }
        if (!Number.isSafeInteger(number)) {
            return Number.MAX_SAFE_INTEGER
        }
        return number
    },
    // Add here other general decoders, e.g. for date/time, enums, etc.

    array: (valueOrArray: string | string[]) => {
        if (Array.isArray(valueOrArray)) {
            return valueOrArray.map(value => decoders.string(value))
        } else {
            return asArray(decoders.string(valueOrArray).split(ARRAY_FORMAT_SEPARATOR))
        }
    },
}

export type UrlPartDecoder<D> = (value: string) => D | undefined

export function decodeValue<T, D>(
    parsedUrlPart: string | string[] | undefined,
    decoder: UrlPartDecoder<D>,
): D | undefined {
    if (parsedUrlPart === undefined) {
        return undefined
    }

    let value: string
    if (Array.isArray(parsedUrlPart)) {
        console.warn('Parsed URL part is an array, while a single value was expected')
        value = parsedUrlPart[0] // not cool, but safer than doing an in-place concatenation
    } else {
        value = parsedUrlPart
    }
    return decoder(value)
}

export function decodeArray<T, D>(
    parsedUrlPart: string | string[] | undefined,
    decoder: UrlPartDecoder<D>,
): D[] | undefined {
    if (parsedUrlPart === undefined) {
        return undefined
    }
    return decoders
        .array(parsedUrlPart)
        ?.map(value => decoder(value))
        .filter(value => value !== undefined) as D[]
}

// ---------------
// BASE URL
// ---------------

const BASE_URL_PORT = getEnvGlobal('baseUrlPort')
const BASE_URL_PROTOCOL = getEnvGlobal('baseUrlProtocol')

export const baseUrl = (ctx?: NextPageContext) => {
    if (isServerSide) {
        if (!ctx) {
            throw new Error('Context is required to generate baseUrl on the server')
        }

        let url = `${BASE_URL_PROTOCOL}://${ctx.req?.headers.host}`
        if (BASE_URL_PORT) {
            url = `${url}:${BASE_URL_PORT}`
        }
        return url
    } else {
        return window.location.origin
    }
}
