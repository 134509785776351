import { ApplicationState } from 'entities/application'
import { LoggedInUser } from 'entities/user'
import { StandardVertical } from 'entities/vertical'
import {
    ArticlesPresetSearchArgs,
    buildPresetSearchUrlFragment,
    JobsPresetSearchArgs,
} from 'lib/presetSearch'
import { addQueryParams, encodePath } from 'lib/url'

const jobRoutes = {
    job: ({
        slug,
        apply,
        publish,
    }: {
        slug: string
        apply?: boolean
        publish?: boolean
    }) => {
        return addQueryParams(`/jobs/${slug}`, { apply, publish })
    },
    newJob: () => '/jobs/new',
    editJob: ({ id }: { id: string }) => `/jobs/${id}/edit`,
}

const jobsRoutes = {
    jobs: () => '/jobs',
    recommendedJobs: () => '/recommended',
    savedJobs: () => '/saved',
    appliedJobs: () => '/applied',

    // Jobsboard (search)
    jobsboard: ({
        searchTerms,
        query,
    }: {
        searchTerms?: string[]
        query?: Record<string, any>
    }) => {
        const base = `/jobs/search/${encodePath(searchTerms)}`
        return addQueryParams(base, query)
    },

    // Preset search
    jobsPresetSearch: (args: JobsPresetSearchArgs) => {
        const urlFragment = buildPresetSearchUrlFragment(args, 'job')
        return `/${urlFragment ? urlFragment : 'jobs/search'}`
    },

    // Company-related
    companyJobs: ({
        companySlug,
        page,
        unpublished,
    }: {
        companySlug: string
        page?: number
        unpublished?: boolean
    }) => {
        return addQueryParams(`/companies/${companySlug}/jobs`, {
            page,
            unpublished,
        })
    },
}

const applicationsRoutes = {
    applications: () => '/applications',
    applicationsNew: (queryParams?: {
        jobId?: string
        tab?: ApplicationState
        companyId?: string
        page?: number
    }) => addQueryParams('/companies/applications', queryParams),

    // Company-related
    oldCompanyApplications: ({
        companyId,
        ...queryParams
    }: {
        companyId?: string
        jobId?: string
        page?: number
        tab?: ApplicationState
    }) => {
        if (!companyId) {
            return addQueryParams('/companies/applications', queryParams)
        }
        return addQueryParams(`/companies/${companyId}/applications`, queryParams)
    },
    companyApplications: () => '/companies/applications',

    // UserType-specific
    userApplications: (currentUser: Pick<LoggedInUser, 'isCompanyUser' | 'isAdmin'>) => {
        if (currentUser.isCompanyUser || currentUser.isAdmin) {
            return routes.companyApplications()
        }
        // otherwise, current user is a Candidate
        return routes.appliedJobs()
    },
}

const articlesRoutes = {
    articles: () => '/learn',
    newArticle: () => '/learn/create',
    article: ({ id }: { id: string }) => `/learn/${id}`,
    editArticle: ({ id }: { id: string }) => `/learn/${id}/edit`,
    savedArticles: () => '/learn/saved',

    // Search
    articlesByAuthor: (author: string) => `/learn/search/${encodePath(author)}`,

    // Preset search
    articlesPresetSearch: (args: ArticlesPresetSearchArgs) => {
        return `/${buildPresetSearchUrlFragment(args, 'article') ?? 'learn/search'}`
    },

    // Company-related
    companyArticles: ({ companySlug, page }: { companySlug: string; page?: number }) => {
        return addQueryParams(`/companies/${companySlug}/articles`, { page })
    },
}

// Article series [W.I.P.]
const seriesRoutes = {
    seriesList: () => '/series',

    series: ({ seriesId }: { seriesId: string }) => `/series/${seriesId}`,
    editSeries: ({ seriesId }: { seriesId?: string }) => `/series/${seriesId}/edit`,
    newSeries: () => '/series/new',
}

const companiesRoutes = {
    companies: () => '/companies',
    newCompany: () => '/companies/new',

    companyProfile: (companySlug: string) => `/companies/${companySlug}`,
}

// Users as seen by other users
const usersRoutes = {
    user: (userId: string, jobId?: string) => {
        if (jobId) {
            return addQueryParams(`/users/${userId}`, { 'job-id': jobId })
        }
        return `/users/${userId}`
    },
}

const userProfileRoutes = {
    // For logged-in user
    currentUserProfile: () => '/profile',
    // For Admins only
    userProfile: (userId: string, jobId: string) => `/profile/${userId}?job-id=${jobId}`,
}

// Universal Search
const universalSearchRoutes = {
    search: (searchTerm: string) => `/search/${encodePath(searchTerm)}`,
}

// In-App Messenger
const messengerRoutes = {
    conversation: ({ convId }: { convId: string }) => `/conversations/${convId}`,
    conversationsAll: () => `/conversations`,
}

const companyUserRoutes = {
    companySettings: () => '/company-settings',
    paymentPackages: (queryParams?: {
        action?: 'applications' | 'publish' | 'edit' | 'integrations'
        job?: string // should be jobId, but for legacy reasons we have `job`, wh.company.payment.sub/job-id
    }) => addQueryParams('/payment/package', queryParams),
}

const adminRoutes = {
    adminJobsForReview: () => '/admin/jobs-for-review',
    adminCompanies: () => '/admin/companies',
    adminArticles: () => '/admin/learn',

    candidates: () => '/candidates',
    newCandidate: () => '/candidates/new',

    createPromotion: ({
        id,
        type,
    }: {
        id: string
        type: 'job' | 'company' | 'article'
    }) => {
        return `/admin/promotions/new/${type}/${id}`
    },
    createCompanyOffer: ({ companyId }: { companyId: string }) => {
        return `/companies/${companyId}/offer`
    },
}

const publicRoutes = {
    // Login
    login: (params?: { redirect?: string }) => {
        const { redirect } = params ?? {}
        const pathname = '/login'
        // for example redirect = '/conversations'
        if (redirect) {
            return addQueryParams(pathname, { redirect })
        }
        return pathname
    },
    loginWith: (provider: 'github' | 'stackoverflow' | 'twitter' | 'email') =>
        `/login/${provider}`,

    // Registration
    register: () => '/register',
    companyRegistration: () => '/company-registration',

    // User-specific
    notificationSettings: () => '/notifications/settings',

    // Marketing pages
    hiring: () => '/hiring',
    pricing: () => '/pricing',

    // Terms & policies
    termsOfService: () => '/terms-of-service',
    privacyPolicy: () => '/privacy-policy',

    // App-wide
    metrics: () => '/metrics',
    feed: () => '/feed',
    sitemap: () => '/sitemap',
}

export const routes = {
    homepage: () => '/',

    // Jobs-related
    ...jobRoutes,
    ...jobsRoutes,
    ...applicationsRoutes,

    // Articles-related
    ...articlesRoutes,
    ...seriesRoutes,

    // Companies-related
    ...companiesRoutes,

    // Users-related
    ...usersRoutes,
    ...userProfileRoutes,

    // Search
    ...universalSearchRoutes,

    // In-App Messenger
    ...messengerRoutes,

    // UserType-specific
    ...companyUserRoutes,
    ...adminRoutes,

    // Public pages
    ...publicRoutes,

    api: {
        cvUpload: () => '/api/cv',
        imageUpload: () => '/api/image',
        logout: () => '/api/logout',
        analytics: () => '/api/analytics-frontend',
        graphql: () => '/api/graphql',
        updatesToken: () => '/api/updates/token',
        createProspect: (query?: Record<string, any>) =>
            addQueryParams(`/api/prospect`, query),
    },
    resource: {
        logo: (vertical: StandardVertical) => `/img/verticals/${vertical}_logo.svg`,
        favicon: (vertical: StandardVertical) => `/img/favicons/${vertical}-32x32.png`,
    },
}
