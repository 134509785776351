import * as S from '@sentry/nextjs'

export class Sentry {
    static captureMessage = S.captureMessage
    static captureException = S.captureException
    static setUser(user: {
        id: string
        type: string
        name: string
        companyName?: string
    }) {
        S.setUser(user)
    }
    static unsetUser() {
        S.configureScope(scope => scope.setUser(null))
    }
}
