import Cookies from 'universal-cookie'
import { CtxRequestWithHeaders } from 'lib/contextNext'

const authToken = (c: Cookies) => c.get('auth_token')
const hasAuthFlag = (c: Cookies) => c.get('wh_authed') === '1'

export const getAuthTokenServer = (ctx: CtxRequestWithHeaders) =>
    authToken(new Cookies(ctx?.req?.headers?.cookie))

export const isAuthenticatedServer = (ctx: CtxRequestWithHeaders): boolean => {
    const cookies = new Cookies(ctx.req?.headers?.cookie)
    return Boolean(authToken(cookies)) || hasAuthFlag(cookies)
}

// auth_token is HttpOnly cookie so we use wh_authed to check if we have token or not
export const isAuthenticatedClient = (): boolean => {
    const cookies = new Cookies(null)
    return hasAuthFlag(cookies)
}
