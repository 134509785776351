import { reject } from 'ramda'
import { Company } from 'entities/company'
import { Location } from 'entities/location'
import { Tag } from 'entities/tag'
import { VisaStatus } from 'entities/visa'

// interfaces

export type UserId = string

export enum UserType {
    Candidate = 'candidate',
    Company = 'company',
    Admin = 'admin',
}

export interface File {
    name?: string
    url?: string
    hash?: string
}
export interface FileOrLink {
    link?: string
    file?: File
}

export interface OtherUrl {
    // values from our db ~ sorted by popularity
    title:
        | 'StackOverflow'
        | 'LinkedIn'
        | 'GitHub'
        | 'Twitter'
        | 'Blog'
        | 'Personal'
        | (string & {})
    url: string
}

export type FeatureIntro = 'in_app_messaging'

export type Skill<T = Tag> = {
    name: string
    tag: T
}

export interface User {
    id: UserId
    email: string
    type: UserType
    name: string
    imageUrl: string
    currentLocation?: Location
    cv?: FileOrLink
    coverLetter?: FileOrLink
    otherUrls?: OtherUrl[]
    companyId?: string
    featureIntros?: FeatureIntro[]
    visaStatus?: VisaStatus[]
    visaStatusOther?: string
    skills?: Skill[]
}

// current user (a.k.a. `me`)

export type MeData = Pick<
    User,
    'id' | 'email' | 'type' | 'name' | 'imageUrl' | 'companyId'
> & {
    company?: Pick<Company, 'id' | 'name' | 'slug' | 'logo' | 'permissions'>
}

export type LoggedInUser = MeData & {
    isAdmin: boolean
    isCandidate: boolean
    isCompanyUser: boolean
}

export const ANONYMOUS = null
export type Anonymous = typeof ANONYMOUS
export type Me = Anonymous | LoggedInUser

// user types

export const Guest = 'guest'
export type UserTypeExt = UserType | typeof Guest

export function getUserTypeExt(me: Me): UserTypeExt {
    return me?.type ?? Guest
}

export const superAdmins = [
    'charlie@functionalworks.com',
    'alex@functionalworks.com',
    'antony@functionalworks.com',
    'daniel.earnshaw@functionalworks.com',
    'patrick@functionalworks.com',
]

export const isGuest = (me: Extract<Me, Anonymous> | Pick<LoggedInUser, 'id'>) =>
    me === ANONYMOUS
export const isAdmin = (user?: Pick<User, 'type'>) => user?.type === UserType.Admin
export const isSuperAdmin = (user?: Pick<User, 'type' | 'email'>) => {
    return isAdmin(user) && superAdmins.includes(user?.email ?? '')
}
export const isCandidate = (user?: Pick<User, 'type'>) =>
    user?.type === UserType.Candidate
export const isCompanyUser = (user?: Pick<User, 'type'>) =>
    user?.type === UserType.Company

export const isAdminOrCompanyOwner = (
    me: Pick<LoggedInUser, 'companyId' | 'isAdmin'> | Anonymous,
    companyId?: string,
): boolean => {
    return Boolean(me && (me.isAdmin || me.companyId === companyId))
}

// user links

const findUrl = (otherUrls: User['otherUrls'], substr?: string, title?: string) => {
    const otherUrl = otherUrls?.find(
        u => (title && u.title === title) || (substr && u.url.includes(substr)),
    )
    return otherUrl?.url
}

type UserLinkType = 'file' | 'GH' | 'SO' | 'web'

export type UserLink = {
    title: string
    url: string
    type: UserLinkType
}

type GetLinks = (user: Pick<User, 'otherUrls' | 'cv' | 'coverLetter'>) => UserLink[]

export const getLinks: GetLinks = user => {
    return [
        // TODO [DEV-1202]: This is a bit wrong separation. Some files can be downloaded, some opened as links.
        // TODO [DEV-1202]: Some CVs are links, i.e. rest under the `user.cv?.link` property.
        { title: 'CV', url: user.cv?.file?.url, type: 'file' },
        // TODO [DEV-1202]: This should come from the Application instead!
        { title: 'Cover Letter', url: user.coverLetter?.file?.url, type: 'file' },
        {
            title: 'GitHub',
            url: findUrl(user?.otherUrls, 'github', 'Github'),
            type: 'GH',
        },
        {
            title: 'Stack Overflow',
            url: findUrl(user?.otherUrls, 'stackoverflow'),
            type: 'SO',
        },
        {
            title: 'Personal Site',
            url: findUrl(user?.otherUrls, undefined, 'Personal'),
            type: 'web',
        },
    ].filter(({ url }) => Boolean(url)) as {
        title: string
        url: string
        type: UserLinkType
    }[]
}

// TODO [DEV-1202]: This is too similar to `getLinks` to be left intact. Merge their logic into one fn.
export const parseOtherUrls = (otherUrls: User['otherUrls']): OtherUrl[] => {
    if (!otherUrls) {
        return []
    }

    const parsedUrls = [
        { title: 'GitHub', url: findUrl(otherUrls, 'github', 'GitHub') },
        {
            title: 'StackOverflow',
            url: findUrl(otherUrls, 'stackoverflow', 'StackOverflow'),
        },
        { title: 'Twitter', url: findUrl(otherUrls, 'twitter', 'Twitter') },
        { title: 'LinkedIn', url: findUrl(otherUrls, 'linkedin', 'LinkedIn') },
        { title: 'Blog', url: findUrl(otherUrls, undefined, 'Blog') },
        { title: 'Personal', url: findUrl(otherUrls, undefined, 'Personal') },
        { title: 'Facebook', url: findUrl(otherUrls, 'facebook') },
        { title: 'Youtube', url: findUrl(otherUrls, 'youtube') },
    ].filter(({ url }) => Boolean(url)) as OtherUrl[]

    const parsedUrlStrings = parsedUrls.map(({ url }) => url)

    return [
        ...parsedUrls,
        ...reject((val: OtherUrl) => parsedUrlStrings.includes(val.url), otherUrls),
    ]
}

export const getCVAndCoverLetter = (user?: Pick<User, 'cv' | 'coverLetter'>) => {
    if (!user) {
        return []
    }

    return [
        { title: 'CV', url: user.cv?.file?.url || user.cv?.link },
        {
            title: 'Cover Letter',
            url: user.coverLetter?.file?.url || user.coverLetter?.link,
        },
    ].filter(({ url }) => Boolean(url)) as { title: string; url: string }[]
}

export const hasFullName = (user: Pick<User, 'name'>) => {
    return user.name.split(' ').length > 1
}
