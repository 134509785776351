import { isEmpty, isNil } from 'ramda'

export function notNullOrUndefined<V>(v: V) {
    return !isNil(v)
}

export function notNullOrUndefinedOrEmpty<V>(v: V) {
    return v !== undefined && v !== null && (!Array.isArray(v) || !isEmpty(v))
}

export function isObject<V>(v: V) {
    return typeof v === 'object' && v !== null && !Array.isArray(v)
}
