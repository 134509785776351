import React from 'react'
import {
    AnyVariables,
    UseQueryResponse as UrqlUseQueryResponse,
    UseQueryArgs,
    useQuery as originalUseQuery,
} from 'urql'
import { isDevelopment } from 'lib/env'
import { removeGraphqlTypename } from 'lib/object'

export type UseQueryResponse<
    Data = any,
    Variables extends AnyVariables = AnyVariables
> = UrqlUseQueryResponse<Data, Variables>

export default function useQuery<
    Data = any,
    Variables extends AnyVariables = AnyVariables
>(
    args: UseQueryArgs<Variables, Data> & { removeTypename?: boolean },
): UseQueryResponse<Data, Variables> {
    const result = originalUseQuery<Data, Variables>(args)

    if (result[0].error && isDevelopment()) {
        console.error(result[0].error)
    }

    return React.useMemo(() => {
        if (args.removeTypename) {
            return [
                { ...result[0], data: removeGraphqlTypename(result[0].data as any) },
                result[1],
            ] as UseQueryResponse<Data, Variables>
        }
        return result
    }, [args.removeTypename, result])
}
