export const pluralize = (n: number, word: string) => (n === 1 ? word : word + 's')

export const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1)

/**
 * A simplified version of the `slugger.core/->slug` fn for FE use only,
 * e.g. stories, mock data, HTML IDs, etc. Please, do not use with real
 * data, especially coming from BE and/or for building internal URIs.
 */
export const slugify = (s: string) =>
    s
        .toLowerCase()
        .trim()
        .replace(/[^a-z0-9 ]/g, '')
        .replace(/\s+/g, '-')

export const nonBreakify = (s: string) => s.replace(/\s+/g, ' ')

export const nonBreakingItemsJoin = (
    items: (string | undefined | null)[],
    separator: string = ', ',
) => {
    return items
        .filter(Boolean) // in fact, this always returns `string[]`
        .map(s => nonBreakify(s!))
        .join(separator)
}
