import React, { createContext, ReactNode, useCallback, useContext } from 'react'
import { Client, gql } from '@urql/core'
import { ANONYMOUS, isAdmin, isCandidate, isCompanyUser, Me, MeData } from 'entities/user'
import { isAuthenticatedClient, isAuthenticatedServer } from 'lib/auth'
import { CtxRequestWithHeaders } from 'lib/contextNext'
import { isServerSide } from 'lib/env'
import { Sentry } from 'lib/sentry'
import useQuery from 'hooks/useQuery'

export const MeContext = createContext<Me>(ANONYMOUS)
export const RefetchMeContext = createContext<{ refetchMe: () => void } | null>(null)

export const ME_QUERY = gql<{ me: MeData }>`
    query me {
        me {
            id
            email
            type
            name
            imageUrl
            companyId
            company {
                id
                slug
                logo
                name
                permissions
            }
        }
    }
`

export default function useMe(): React.ContextType<typeof MeContext> {
    const meData = React.useContext(MeContext)

    React.useEffect(() => {
        if (meData) {
            Sentry.setUser({
                id: meData.id,
                type: meData.type,
                name: meData.name,
                companyName: meData.company?.name,
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [meData?.id, meData?.type, meData?.name, meData?.company?.name])

    return meData
}

export const useMeForPrivatePage = () => {
    const me = useMe()
    if (!me) {
        throw new Error('this page is supposed to be private')
    }
    return me
}

//

function transformMeData(meData?: MeData): Me {
    if (meData) {
        return {
            ...meData,
            isAdmin: isAdmin(meData),
            isCandidate: isCandidate(meData),
            isCompanyUser: isCompanyUser(meData),
        }
    }
    return ANONYMOUS
}

function fetchMeInternal(client: Client) {
    return client
        .query(ME_QUERY, {})
        .toPromise()
        .then(({ data, error }) => {
            if (error) {
                console.error(error)
                return transformMeData()
            }
            // don't return a rejected Promise, it may lead to an HTTP 500
            return transformMeData(data?.me)
        })
}

export function fetchMe(client: Client, ctx: CtxRequestWithHeaders) {
    // NB: Do not try to fetch data about a user when auth token is not present!
    return (isServerSide ? isAuthenticatedServer(ctx) : isAuthenticatedClient())
        ? fetchMeInternal(client)
        : Promise.resolve(ANONYMOUS)
}

//

export const MeWithRefetchProvider = ({
    children,
    isAuthenticated,
}: {
    children: ReactNode
    isAuthenticated: boolean
}) => {
    const [meResult, reExecuteQuery] = useQuery({
        query: ME_QUERY,
        pause: !isAuthenticated,
    })

    const me = meResult.error ? undefined : meResult.data?.me

    const refetchMe = useCallback(() => {
        reExecuteQuery({ requestPolicy: 'network-only' })
    }, [reExecuteQuery])

    return (
        <MeContext.Provider value={transformMeData(me)}>
            <RefetchMeContext.Provider value={{ refetchMe }}>
                {children}
            </RefetchMeContext.Provider>
        </MeContext.Provider>
    )
}

export const useRefetchMe = () => {
    const value = useContext(RefetchMeContext)
    if (!value) {
        throw new Error('Please use RefetchMeContext.Provider')
    }
    return value.refetchMe
}
