// @ts-ignore
import omitDeep from 'omit-deep'
import { isObject, notNullOrUndefined } from 'lib/predicates'

export const removeNullAndUndefined = (obj: any): any => {
    return Object.fromEntries(
        // convert object to array of entries - [[key,value]]
        Object.entries(obj)
            // filter out non null or undefined values
            .filter(([_, val]) => notNullOrUndefined(val))
            // perform a recursive action if a value is an object or else return the value
            .map(([key, val]) => {
                return [key, isObject(val) ? removeNullAndUndefined(val) : val]
            }),
    )
}

export const removeGraphqlTypename = (object?: Object) => omitDeep(object, ['__typename'])

export const toOptions = (obj: any) => {
    return Object.values(obj).map(val => ({
        value: val,
        label: val,
    }))
}
