function defaultEq<T>(a: T, b: T) {
    return a === b
}

export function toggleElement<T>(
    arr: T[],
    element: T,
    eq: (a: T, b: T) => boolean = defaultEq,
) {
    const isPresent = arr.some(elm => eq(elm, element))
    if (isPresent) {
        return arr.filter(elm => !eq(elm, element))
    }
    return [...arr, element]
}

export function asArray<T>(obj?: T[] | T) {
    if (obj !== undefined) {
        return Array.isArray(obj) ? obj : [obj]
    }
}

export function append<T>(arr: T[] | undefined, val: T | undefined) {
    if (val !== undefined && (arr === undefined || !arr.includes(val))) {
        return [...(arr ?? []), val]
    }
    return arr
}

export function unique<T>(arr: T[] | undefined): T[] {
    return Array.from(new Set(arr ?? []))
}
