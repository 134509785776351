import { SSRExchange, withUrqlClient } from 'next-urql'
import { ClientOptions, dedupExchange, fetchExchange } from '@urql/core'
import { devtoolsExchange } from '@urql/devtools'
import {
    CacheExchangeOpts,
    cacheExchange as cacheExchangeNormalized,
} from '@urql/exchange-graphcache'
import { getEnv } from 'config'
import { mergeDeepLeft } from 'ramda'
import { routes } from 'routes'
import { getAuthTokenServer } from 'lib/auth'
import { CtxRequestWithHeaders } from 'lib/contextNext'
import { isServerSide } from 'lib/env'

const globalCacheExchangeOptions: CacheExchangeOpts = {
    keys: {
        ApplicationStateFrequency: () => null,
        CompanyIntegrations: () => null,
        WorkableIntegration: () => null,
        SpokenLanguage: () => null,
        Remuneration: () => null,
        Location: () => null,
        RemoteInfo: () => null,
        Timezone: () => null,
        TimezoneDelta: () => null,
        NotificationSetting: () => null,
        NotificationSettings: () => null,
        Pagination: () => null,
        Blogs: () => null,
        Jobs: () => null,
        CityInfo: () => null,
        associated_tags: () => null,
        SearchFacet: () => null,
        SearchResults: () => null,
        Skill: () => null,
        Cv: () => null,
        CoverLetter: () => null,
        Approval: () => null,
        // @ts-ignore
        JobApplicationStateFrequencies: x => x.jobId,
        // @ts-ignore
        File: x => x.hash,
        // @ts-ignore
        JobApplicationStateFrequencies: x => x.jobId,
        JobAnalyticsDataPoint: () => null,
        // @ts-ignore
        Company: x => x.id,
        JobAnalytics: () => null,
        Offer: () => null,
        Other_url: () => null,
    },
}

export function getUrqlClientConfig(
    ssrExchange: SSRExchange,
    ctx?: CtxRequestWithHeaders,
    normalizedCacheOptions?: CacheExchangeOpts,
): ClientOptions {
    let exchanges = [
        devtoolsExchange,
        dedupExchange,
        cacheExchangeNormalized(
            mergeDeepLeft(
                globalCacheExchangeOptions,
                normalizedCacheOptions || {},
            ) as CacheExchangeOpts,
        ),
        ssrExchange,
        fetchExchange,
    ]
    return {
        url: getEnv('apiUrl') + routes.api.graphql(),
        fetchOptions: {
            mode: 'cors',
            credentials: 'include',
            headers: (() => {
                if (isServerSide) {
                    return {
                        Cookie: `auth_token=${getAuthTokenServer(ctx!)}`,
                    } as Record<string, string>
                }
            })(),
        },
        exchanges,
    }
}

export const withGql = ({
    normalizedCacheOptions,
}: {
    normalizedCacheOptions?: CacheExchangeOpts
}) => {
    return withUrqlClient(
        (ssrExchange, ctx) =>
            getUrqlClientConfig(ssrExchange, ctx, normalizedCacheOptions),
        {
            ssr: true,
        },
    )
}
