import { findPresetJobsLocation } from 'entities/location'
import { Tag } from 'entities/tag'
import { nonBreakingItemsJoin } from 'lib/text'

const PresetSearchEntities = {
    job: {
        slug: 'jobs',
        label: 'Jobs',
        route: 'jobsPresetSearch',
    },
    article: {
        slug: 'articles',
        label: 'Articles',
        route: 'articlesPresetSearch',
    },
} as const
export type PresetSearchEntity = keyof typeof PresetSearchEntities

export type JobsPresetSearchArgs = {
    tech?: Pick<Tag, 'slug'>
    location?: {
        slug: string
        label?: string
    }
    remote?: boolean
}

export type ArticlesPresetSearchArgs = {
    tech?: Pick<Tag, 'slug'>
}

export type PresetSearchArgs = JobsPresetSearchArgs | ArticlesPresetSearchArgs

export const buildPresetSearchUrlFragment = (
    args: PresetSearchArgs,
    entity: PresetSearchEntity,
) => {
    // According to `wh.preset-search` we may have:
    // 1. /{tech}-jobs
    // 2. /jobs-in-{location}
    // 3. /{tech}-jobs-in-{location}
    // 4. /remote-jobs
    // 5. /remote-{tech}-jobs
    let parts: string[] = []

    if ('remote' in args && args.remote) {
        parts = [...parts, 'remote']
    }
    if (args.tech?.slug) {
        parts = [...parts, args.tech.slug]
    }

    const entitySlug = PresetSearchEntities[entity].slug
    parts = [...parts, entitySlug]

    if ('location' in args && args.location) {
        parts = [...parts, 'in', args.location.slug]
    }

    const full = parts.join('-')

    return full === entitySlug ? undefined : full
}

type JobsPresetSearchTitleArgs = {
    techLabel?: string
    locationSlug?: string
    remote?: boolean
}

type ArticlesPresetSearchTitleArgs = {
    techLabel?: string
}

export type PresetSearchTitleArgs =
    | JobsPresetSearchTitleArgs
    | ArticlesPresetSearchTitleArgs

const tmpSeparator = '_' // do not use '+' and other special characters
const unbreakifyRE = new RegExp(tmpSeparator, 'g')

export const buildPresetSearchTitle = (
    args: PresetSearchTitleArgs,
    entity: PresetSearchEntity,
) => {
    let parts: string[] = []

    if ('remote' in args && args.remote) {
        parts = [...parts, 'Remote']
    }
    if (args.techLabel) {
        parts = [...parts, args.techLabel.replace(/\s+/g, tmpSeparator)]
    }

    const entityLabel = PresetSearchEntities[entity].label
    parts = [...parts, entityLabel]

    if ('locationSlug' in args && args.locationSlug) {
        const location = findPresetJobsLocation(args.locationSlug)
        if (location) {
            parts = [...parts, `in ${location.label}`]
        }
    }

    const full = nonBreakingItemsJoin(parts, ' ').replace(unbreakifyRE, ' ')

    return full === entityLabel ? undefined : full
}
