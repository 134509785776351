import { countryCodeToCountryName } from 'entities/country'
import { slugify } from 'lib/text'

export type Location = {
    country?: string
    countryCode?: string
    city?: string
    street?: string
    postCode?: string
    state?: string
    administrative?: string
    region?: string
    subRegion?: string
    longitude?: number
    latitude?: number
}

export const format = (l: Pick<Location, 'city' | 'country'>) => {
    return [l.city, l.country].filter(Boolean).join(', ')
}

// NB: This array has to match `wh.common.data/preset-job-cities`.
export const presetJobsCities = [
    'London',
    'New York',
    'San Francisco',
    'Berlin',
    'Barcelona',
]

export type PresetLocation = Pick<Location, 'city' | 'countryCode' | 'country'> & {
    label: string
    slug: string
}

export const presetJobsCitiesWithSlugs = presetJobsCities.map(city => {
    return {
        city,
        label: city,
        slug: slugify(city),
    }
}) as PresetLocation[]

// NB: This array has to match `wh.common.data/preset-job-country-codes`.
export const presetJobsCountryCodes = ['US', 'GB', 'DE']

export const presetJobsCountriesWithSlugs = presetJobsCountryCodes.map(countryCode => {
    const country = countryCodeToCountryName[countryCode]
    return {
        country,
        countryCode,
        label: country,
        slug: slugify(country),
    }
}) as PresetLocation[]

export const findPresetJobsLocation = (locationSlug: string | undefined) => {
    if (!locationSlug) {
        return undefined
    }
    return [...presetJobsCitiesWithSlugs, ...presetJobsCountriesWithSlugs].find(
        location => location.slug === locationSlug,
    )
}
